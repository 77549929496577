<template>
  <div>
    <aad-loader v-if="this.allSessions == undefined || this.allSessions.length == 0"></aad-loader>
    <div v-else>
      <router-link
        v-if="cmeOnly"
        :to="{ name: 'Login', query: { redirect: '/', name: 'Logout' } }"
        @click.native="logout"
        class="btn btn-primary-solid"
      >
        Logout
      </router-link>

      <aad-title>CME Credit Claims <span v-if="cmeOnly">- {{loggedInUser.name}}</span></aad-title>
      <div v-if="isLoggedInUserVerified">
        <div class="alert alert-info mb-4" role="alert">
          <div>
            <aad-icon name="info-circle-filled" class="warning-icon"></aad-icon>
            {{ currentMeeting.Name }} CME Information:
            <a
              v-if="this.config && this.config.CmePdfLink"
              class="font-weight-bold reg-text-link-light-bg"
              target="_blank"
              :href="this.config.CmePdfLink"
            >
              Click Here
            </a>
          </div>
        </div>

        <div class="alert alert-primary mb-4" role="alert">
          <div>
            <aad-icon
              name="warning-circle-filled"
              class="warning-icon"
            ></aad-icon>
            Please also take a minute to fill out the
            <router-link
              :to="{ name: 'CMESurvey', params: { sessionCode: 'OVERALL' } }"
              class="reg-text-link-light-bg font-weight-bold"
            >
              Overall Evaluation </router-link
            >.
          </div>
        </div>

        <div class="alert alert-primary mb-4" role="alert">
          <div>
            <aad-icon
              name="summary-circle-filled"
              class="warning-icon"
            ></aad-icon>
            To access a certificate of participation with a transcript of your
            credits for this meeting:
            <a
              class="reg-text-link-light-bg font-weight-bold"
              target="_blank"
              :href="`https://meetings.aad.org/Evaluations/Gateway/Certificate/${getMeetingCode}/${loggedInUser.registrationCode}/${loggedInUser.registrationHash}`"
              >Click Here</a
            >
          </div>
        </div>

        <p class="font-weight-bold">
          Use the search bar below to find the sessions that you attended. Click
          the claim button and fill out the form to submit for your credit.
        </p>
        <input
          id="txtSearch"
          type="text"
          placeholder="Search Sessions"
          aria-label="Search"
          class="form-control"
          v-model="searchText"
        />
        <div class="row">
          <div class="col-md-6 order-md-2 p-2" v-if="this.currentMeeting">
            <h3 class="p-2" v-if="!isCmeSingleDay">
              Claimed Credits ({{ getNumberOfCreditsClaimed() }} /
              {{ currentMeeting.CmeMaxLimit }})
            </h3>
            <div v-if="allSessions && allSessions.length > 0">
              <div
                class="meeting-app-info-block"
                v-for="limit in currentMeeting.CmeDailyLimits"
                :key="limit.Day"
              >
                <div class="meeting-app-info-header row">
                  <h4 v-if="!isCmeSingleDay">
                    {{ getLimitDate(limit) }} - ({{
                      getNumberOfCreditsClaimed(getLimitDate(limit))
                    }}
                    / {{ limit.CreditAmount }})
                  </h4>
                  <h4 v-else>
                    Claimed Credits
                     - ({{
                      getNumberOfCreditsClaimed(getLimitDate(limit))
                    }}
                    / {{ limit.CreditAmount }})
                  </h4>
                </div>
                <div class="meeting-app-info-body p-1">
                  <div
                    v-if="
                      cmeSessions &&
                      cmeSessions.length > 0 &&
                      getNumberOfCreditsClaimed(getLimitDate(limit)) > 0
                    "
                  >
                    <CME-session-card
                      :session="session"
                      class="meeting-app-info-item"
                      v-for="session in cmeSessions.filter(
                        (session) =>
                          isCreditClaimActiveBySessionCode(
                            session.SessionCode
                          ) && getSessionDate(session) == getLimitDate(limit)
                      )"
                      :key="session.id"
                    ></CME-session-card>
                  </div>
                  <div v-else class="p-2 font-weight-bold">
                    No claimed CME credits.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 order-md-1 p-2">
            <div
              class="meeting-app-info-block"
              v-if="allSessions && allSessions.length > 0"
            >
              <div class="meeting-app-info-header row">
                <h4>All Sessions</h4>
              </div>
              <div class="meeting-app-info-body p-1">
                <div v-if="cmeSessions && cmeSessions.length > 0">
                  <div
                    v-for="session in cmeSessions"
                    :key="session.id"
                    class="meeting-app-info-item"
                  >
                    <CME-session-card :session="session"></CME-session-card>
                  </div>
                </div>
                <div v-else>No Available Sessions</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p>
          You must be verified on site in order to claim CME credits. If you
          believe you are seeing this in error, please try refreshing your web
          browser.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CMESessionCard from "@/components/CMESessionCard.vue";
import { formatDate, formatSecondsToDate } from "@/mixins/formatDate.js";
import baseConfig from "@/store/baseConfig.js";
import AadLoader from "@/components/AadLoader.vue";

export default {
  data() {
    return {
      searchText: "",
    };
  },
  mounted() {
    this.getSessions();
    this.getSpeakers();
    this.getCMEClaims();
  },
  methods: {
    getSessions() {
      this.$store.dispatch("loadSessions");
    },
    getSpeakers() {
      this.$store.dispatch("loadSpeakers");
    },
    getCMEClaims() {
      this.$store.dispatch("loadAttendeeCMEClaims");
    },
    getLimitDate(limit) {
      return formatDate(new Date(limit.Day));
    },
    getSessionDate(session) {
      return formatSecondsToDate(session.StartTime.seconds);
    },
    // Only shows on CME-only applications
    logout: function () {
      this.$store.dispatch("logout");
    },
  },
  components: {
    CMESessionCard,
    AadLoader,
  },
  computed: {
    ...mapGetters([
      "allSessions",
      "getSessionById",
      "getSpeakerById",
      "allSpeakers",
      "isLoggedIn",
      "getMeetingCode",
      "loggedInUser",
      "currentMeeting",
      "isCreditClaimActiveBySessionCode",
      "isLoggedInUserVerified",
      "getNumberOfCreditsClaimed",
    ]),
    ...mapState(["cmeOnly", "isCmeSingleDay"]),
    config() {
      return baseConfig;
    },
    cmeSessions() {
      return this.allSessions.filter((session) => {
        return (
          session.Name.toLowerCase().includes(this.searchText.toLowerCase()) ||
          session.SessionCode.toLowerCase().includes(
            this.searchText.toLowerCase()
          )
        );
      });
    },
  },
};
</script>
<style scoped>
.warning-icon {
  width: 24px;
  height: 24px;
}
</style>
